import React from 'react'

export const TestimonialV2 = ({fullName, testimonialText, jobTitle, companyName}) => {

  return (
    <div className="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:my-2 md:px-2 md:w-full lg:my-5 lg:px-5 lg:w-10/12 xl:my-5 xl:px-5 xl:w-10/12">
                <div className="flex flex-col bg-white rounded-xl shadow-inner mx-auto my-10  sm:w-9/12 md:w-10/12 lg::w-2/3 xl:w-9/12 w-full">
                  <blockquote className="sm:m-4 md:m-8 lg:m-8 xl:m-10 m-4  bg-white border-teal-600 border-l-2">
                    <p className= "font-semibold m-3 text-shadow-xl "> "{testimonialText}" </p>
                  </blockquote>
                  <div className= "text-white bg-pink-600 p-8 rounded-b-xl bg-whitemd:flex md:items-center">
                    
                    <div>
                      <p className="font-bold"> {fullName} </p>
                      <p>{jobTitle} <span className="text-teal-900 font-bold">|</span> {companyName}</p>
                    </div>
                  </div>
                </div>
    </div>


  );
};

const TestimonialV1 = ({fullName, testimonialText, jobTitle, companyName}) => {

    return (
      <div className="my-2 px-2 w-full overflow-hidden sm:my-2 sm:px-2 sm:w-1/2 md:my-2 md:px-2 md:w-full lg:my-5 lg:px-5 lg:w-1/3 xl:my-5 xl:px-5 xl:w-10/12">
      <div className="flex flex-col bg-white rounded-xl shadow-2xl mx-auto my-10  sm:w-9/12 md:w-10/12 lg::w-2/3 w-full heropattern-endlessclouds-teal-600">
      <blockquote className="sm:m-4 md:m-8 lg:m-8 xl:m-10 m-4  bg-white ">
        <p className= "font-semibold m-3 text-sm md:text-base lg:text-base xl:text-base"> " {testimonialText} " </p>
      </blockquote>
      <div className= "text-white bg-pink-600 p-8 rounded-b-xl md:flex md:items-center">                    
        <div>
          <p className="font-bold"> {fullName} </p>
          <p>{jobTitle} <span className="text-teal-600">|</span> {companyName}</p>
        </div>
      </div>
  </div>
      </div>


    );
};

export default TestimonialV1;